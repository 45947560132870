#weatherboard {
  position: absolute;
  height: 100%;
  width: 100%;
}

.weatheroptions {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 200px;
}

.weatheroptions:hover .weatherswitches{
    display: flex;
}

.button {
  background: none padding-box rgb(255, 255, 255);
  display: table-cell;
  border: 0px;
  margin: 0px;
  padding: 0px 17px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  direction: ltr;
  overflow: hidden;
  text-align: left;
  height: 40px;
  vertical-align: middle;
  color: rgb(86, 86, 86);
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  min-width: 64px;
  flex: 0 0 40px;
}

.weatherswitches {
  display: none;
  width: 200px;
  height: 250px;
  overflow: auto;
  left: 0;
  flex-direction: column;
  margin-top: 10px;
}
